import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ContentComponent } from './component/layout/content/content.component';
import { HeaderComponent } from './component/header/header.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { FeathericonComponent } from './component/feathericon/feathericon.component';
import { FooterComponent } from './component/footer/footer.component';
import { NotificationComponent } from './component/header/notification/notification.component';
import { BookmarkComponent } from './component/header/bookmark/bookmark.component';
import { MessageComponent } from './component/header/message/message.component';
import { CartComponent } from './component/header/cart/cart.component';
import { ThemeComponent } from './component/header/theme/theme.component';
import { LanguageComponent } from './component/header/language/language.component';
import { ProfileComponent } from './component/header/profile/profile.component';
import { SvgIconComponent } from './component/svg-icon/svg-icon.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { SearchComponent } from './component/header/search/search.component';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TapToTopComponent } from './component/tap-to-top/tap-to-top.component';
import { LoaderComponent } from './component/loader/loader.component';
import { ReUseableTableComponent } from './component/re-useable-table/re-useable-table.component';
import { ReUseableFormComponent } from './component/re-useable-form/re-useable-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { AccessControlDirective } from './directives/access-control.directive';
import { ConfirmationBoxComponent } from './component/confirmation-box/confirmation-box.component';
import { NumericOnlyDirective } from './directives/numeric-only.directive';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { ChangePasswordComponent } from './component/header/change-password/change-password.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgApexchartsModule } from 'ng-apexcharts';
import { CommonChartComponent } from './component/charts/common-chart.component';
import { CustomPaginationComponent } from './component/custom-pagination/custom-pagination.component';
import { ResizableColumnDirective } from '../resizable-column.directive';
import { SplitOnCommaPipe } from '../split-on-comma.pipe';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const DIRECTIVES = [AccessControlDirective, NumericOnlyDirective, SplitOnCommaPipe ]


@NgModule({
  declarations: [
    ContentComponent,
    HeaderComponent,
    SidebarComponent,
    FeathericonComponent,
    FooterComponent,
    NotificationComponent,
    BookmarkComponent,
    MessageComponent,
    CartComponent,
    ThemeComponent,
    LanguageComponent,
    ProfileComponent,
    SvgIconComponent,
    BreadcrumbComponent,
    SearchComponent,
    TapToTopComponent,
    LoaderComponent,
    ReUseableTableComponent,
    ReUseableFormComponent,
    ...DIRECTIVES,
    ConfirmationBoxComponent,
    ChangePasswordComponent,
    CommonChartComponent,
    CustomPaginationComponent,
    ResizableColumnDirective
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot(),
    ClipboardModule,
    NgSelectModule,
    NgbPaginationModule,
    NgScrollbarModule,
    NgApexchartsModule
  ],
  exports: [
    ContentComponent,
    FeathericonComponent,
    LoaderComponent,
    SvgIconComponent,
    BreadcrumbComponent,
    TapToTopComponent,
    TranslateModule,
    ReUseableFormComponent,
    ReUseableTableComponent,
    NgSelectModule,
    NgbPaginationModule,
    CustomPaginationComponent,
    ...DIRECTIVES,
    CommonChartComponent,
    ResizableColumnDirective
  ],
  providers: [
    DatePipe
  ]
})
export class SharedModule { }
