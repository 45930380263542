import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

export interface Menu {
  id?: number;
  moduleName?: string,
  accessType?: string
  headTitle1?: string;
  mainTitle?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  item?: Menu[]
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
}

)
export class NavservicesService {

  public language: boolean = false;
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;
  public horizontal: boolean = window.innerWidth < 991 ? false : true;
  public isDisplay: boolean;
  public pinned: boolean;
  public search: boolean;
  public isShow: boolean = false;

  constructor() { }


  @HostListener('window:resize', ['$event'])


  Nvabarmenu: Menu[] = [

    {
      id: 1,
      icon: 'home',
      mainTitle: 'Home',
      headTitle1: 'Home',
      type: 'link',
      active: false,
      path: '/dashboard/admin-dashboard'
      // item: [
      //   {
      //     title: 'Dashboard',
      //     icon: 'home',
      //     type: 'link',
      //     active: true,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Opportunities',
      //     icon: 'calender',
      //     type: 'link',
      //     active: false,
      //     path: '/opportunities'
      //   },
      //   {
      //     title: 'Manage Users',
      //     icon: 'user',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Manage Branch',
      //     icon: 'social',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Manage Email',
      //     icon: 'email',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Reports',
      //     icon: 'table',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Manage Channel',
      //     icon: 'social',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Manage Product',
      //     icon: 'bonus-kit',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Manage Territory',
      //     icon: 'social',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Manage Customers',
      //     icon: 'user',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'SO Number Entry',
      //     icon: 'table',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Log Report',
      //     icon: 'table',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Inactive User Leads',
      //     icon: 'user',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Delete Contract',
      //     icon: 'Form',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Bulk Upload',
      //     icon: 'layout',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Settings',
      //     icon: 'layout',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   },
      //   {
      //     title: 'Live Location',
      //     icon: 'layout',
      //     type: 'link',
      //     active: false,
      //     path: '/dashboard/admin-dashboard'
      //   }
      // ]
    },
    {
      // id: 7,
      id: 2,
      icon: 'social',
      mainTitle: 'Manage Branch',
      headTitle1: 'Manage Branch',
      moduleName: 'ManageBranch',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'manage-branch'
    },
    {
      // id: 25,
      id: 3,
      icon: 'charts',
      mainTitle: 'Manage Vertical',
      headTitle1: 'Manage Vertical',
      moduleName: 'Vertical',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'vertical'
    },
    {
      // id: 11,
      id: 4,
      icon: 'learning',
      mainTitle: 'Manage Territory',
      headTitle1: 'Manage Territory',
      moduleName: 'ManageTerritory',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        {
          title: 'Geo',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-territory/geo/list',
        },
        {
          title: 'Country',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-territory/country/list',
        },
        {
          title: 'Region',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-territory/region/list',
        },
        {
          title: 'State/Branch',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-territory/state/list',
        },
        {
          title: 'District',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-territory/district/list',
        },
        {
          title: 'City/Town',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-territory/city/list',
        }
      ]
    },
    {
      id: 5,
      // id: 10,
      icon: 'user',
      mainTitle: 'Manage Product',
      headTitle1: 'Manage Product',
      moduleName: 'ManageProduct',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        {
          title: 'Category',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-product/category/list',
        },
        {
          title: 'Segment',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-product/segment/list',
        },
        {
          title: 'Competitor',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-product/competitor/list',
        },
        {
          title: 'Sub System',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-product/sub-system/list',
        },
        {
          title: 'Products',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-product/products/list',
        },
        // {
        //   title: 'Demo',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: 'manage-product/demo/list',
        // },
        // {
        //   title: 'Financial Year',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: 'manage-product/financial-year/list',
        // },
        // {
        //   title: 'User Product Target',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: 'manage-product/user-product-target/list',
        // }
      ]
    },
    {
      id: 6,
      // id: 23,
      icon: 'charts',
      mainTitle: 'Manage Roles-Access',
      headTitle1: 'Manage Roles-Access',
      moduleName: 'RolesAndAccess',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'roles-access'
    },
    {
      id: 7,
      // id: 6,
      icon: 'user',
      mainTitle: 'Manage Users',
      headTitle1: 'Manage Users',
      moduleName: 'ManageUsers',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'manage-user/users/list'
    },
    {
      // id: 27,
      id: 8,
      icon: 'charts',
      mainTitle: 'Approvals',
      headTitle1: 'Approvals',
      moduleName: 'Approvals',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'approvals',
      item: [
        {
          title: 'CNote Approval',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'approvals/contract_note_approval_list',
        },
        {
          title: 'Quote Approval',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'approvals/quote_approval_list',
        },
        // {
        //   title: 'Purchase Order Approval',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: 'approvals/po_approval_list',
        // }
      ]
    },
    {
      // id: 28,
      id: 9,
      icon: 'charts',
      mainTitle: 'Customer Approval',
      headTitle1: 'Customer Approval',
      moduleName: 'CustomerApproval',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'customer-approval'
    },
    {
      // id: 8,
      id: 10,
      icon: 'user',
      mainTitle: 'Reports',
      headTitle1: 'Reports',
      moduleName: 'Reports',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        {
          title: 'Funnel Report',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'reports/funnel_report',
        },
        {
          title: 'Opportunity Lost Report',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'reports/opportunity-lost-report'
        },
        {
          title: 'Open Order Report',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'reports/open-order-report'
        },
        // {
        //   title: 'Fresh Repeat Business Report',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: 'reports/fresh-repeat-business-report'
        // },
        {
          title: 'Daily Visit Plan Report',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'reports/daily_visit_plan_report',
        },
        {
          title: 'Opportunity Performance Report',
          icon: 'table',
          type: 'link',
          active: true,
          // path: 'reports/lead_performance_report',
          path: 'reports/opportunity_performance_report',
        },
        {
          title: 'Visit Performance Report',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'reports/visit_performance_report',
        },
        {
          title: 'Margin Analysis Report',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'reports/margin_analysis_report',
        },
        {
          title: 'C-Note Analysis Report',
          icon: 'table',
          type: 'link',
          active: true,
          // path: 'reports/c-note-margin_analysis_report',
          path: 'reports/c-note-analysis-report',
        },
        {
          title: 'Order Lost Analysis Report',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'reports/order_lost_analysis_report',
        },
        // {
        //     title: 'Outstanding Analysis Report',
        //     icon: 'table',
        //     type: 'link',
        //     active: true,
        //     path: 'reports/outstanding_analysis_report',
        // },
        // {
        //     title: 'Stock In Hand Analysis Report',
        //     icon: 'table',
        //     type: 'link',
        //     active: true,
        //     path: 'reports/stock_in_hand_analysis_report',
        // },

      ]


    },
    {
      id: 11,
      // id: 29,
      icon: 'calender',
      mainTitle: 'Calender',
      headTitle1: 'Calender',
      moduleName: 'Calander',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'calendar'
    },
    {
      // id: 21,
      id: 12,
      icon: 'charts',
      mainTitle: 'Manage Lead',
      headTitle1: 'Manage Lead',
      moduleName: 'Leads',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'manage-lead'
    },
    {
      // id: 24,
      id: 13,
      icon: 'charts',
      mainTitle: 'Opportunity',
      headTitle1: 'Opportunity',
      moduleName: 'Opportunities',
      accessType: 'read',
      type: 'link',
      active: false,
      // path:'opportunity/list-Opportunity'
      path: 'opportunity/list-Opportunity',
      item: [
        {
          title: 'Open',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'opportunity/list-Opportunity',
        },
        {
          title: 'Closed',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'opportunity/closed-Opportunity',
        }
      ]
    },
    {
      // id: 13,
      id: 14,
      icon: 'user',
      mainTitle: 'SO Number Entry',
      headTitle1: 'SO Number Entry',
      moduleName: 'SONumberEntry',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'so-number-entry',
      item: [
        {
          title: 'Open',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'so-number-entry/OPEN',
        },
        {
          title: 'Close',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'so-number-entry/CLOSED',
        }
      ]
    },
    {
      // id: 22,
      id: 15,
      icon: 'charts',
      mainTitle: 'Plan Visit/Demo',
      headTitle1: 'Plan Visit/Demo',
      moduleName: 'PlanAndDemo',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        {
          title: 'Plan a Visit',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'vist-demo/visit',
        },
        // {
        //   title: 'Plan a Demo',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: 'vist-demo/demo',
        // }
      ]
    },
    {
      // id: 12,
      id: 16,
      icon: 'table',
      mainTitle: 'Manage Customer',
      headTitle1: 'Manage Customer',
      moduleName: 'ManageCustomer',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        {
          title: 'Customer',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-customer/customers/list',
        },
        {
          title: 'Contact',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-customer/contact/list',
        },
        {
          title: 'Speciality',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-customer/speciality/list',
        },
        {
          title: 'Customer Category',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-customer/customer-type/list',
        },
        {
          title: 'Customer Sub Category',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-customer/customer-sub-category/list',
        }
      ]
    },
    {
      // id: 26,
      id: 17,
      icon: 'charts',
      mainTitle: 'Manage Document',
      headTitle1: 'Manage Document',
      moduleName: 'ManageDocument',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'manage-document'
    },
    {
      // id: 19,
      id: 18,
      icon: 'maps',
      mainTitle: 'Live Location',
      headTitle1: 'Live Location',
      moduleName: 'LiveLocation',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        {
          title: 'Live Location',
          icon: 'maps',
          type: 'link',
          active: true,
          path: 'live-locations',
        },
        {
          title: 'Live Location Report',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'live-locations/report',
        },// {
        //   title: 'Zoom Live Location',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: '',
        // },
      ]
    },
    {
      // id: 18,
      id: 19,
      icon: 'user',
      mainTitle: 'Settings',
      headTitle1: 'Settings',
      moduleName: 'Settings',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        {
          title: 'General Settings',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'settings/general_settings',
        },
        // {
        //   title: 'Margin Bands',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: '',
        // },
        // {
        //   title: 'Incentives Settings',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: '',
        // },
        // {
        //   title: 'Manage Free-supply Item %',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: '',
        // },
        // {
        //   title: 'Condition For Approval Mail',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: '',
        // },
        // {
        //   title: 'Quote Revision Status Change',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: '',
        // }
      ]
    },
    {
      // id: 16,
      id: 20,
      icon: 'user',
      mainTitle: 'Delete Contract Notes',
      headTitle1: 'Delete Contract Notes',
      moduleName: 'DeleteContractNotes',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'delete_contract_note'
    },
    {
      // id: 14,
      id: 21,
      icon: 'user',
      mainTitle: 'Log Report',
      headTitle1: 'Log Report',
      moduleName: 'LogReport',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        // {
        //   title: 'User Logs',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: '',
        // },
        // {
        //   title: 'Punch In Report',
        //   icon: 'table',
        //   type: 'link',
        //   active: true,
        //   path: 'logreport/punch_in_report',
        // },
        {
          title: 'Punch In Logs',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'logreport/punchinlogs',
        }
      ]
    },
    {
      // id: 2,
      id: 22,
      icon: 'social',
      mainTitle: 'Company',
      headTitle1: 'Company',
      moduleName: 'ManageCompany',
      accessType: 'read',
      type: 'link',
      active: false,
      path: '/manage-company/company'
    },
    {
      // id: 3,
      id: 23,
      icon: 'user',
      mainTitle: 'Admin User',
      headTitle1: 'Admin user',
      moduleName: 'ManageAdminUser',
      accessType: 'read',
      type: 'link',
      active: false,
      path: '/admin/adminUser'
    },
    {
      // id: 4,
      id: 24,
      icon: 'user',
      mainTitle: 'Manage Web Enquiries',
      headTitle1: 'Manage Web Enquiries',
      moduleName: 'ManageWebEnquiries',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'manage-web-enquiries'
    },
    {
      // id: 5,
      id: 25,
      icon: 'user',
      mainTitle: 'Manage Email',
      headTitle1: 'Manage Email',
      moduleName: 'ManageEmail',
      accessType: 'read',
      type: 'link',
      active: false,
      path: ''
    },
    {
      // id: 9,
      id: 26,
      icon: 'ui-kits',
      mainTitle: 'Manage Channel Partner',
      headTitle1: 'Manage Channel Partner',
      moduleName: 'ManageChannelPartner',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'manage-channel-partner'
    },

    {
      // id: 15,
      id: 27,
      icon: 'user',
      mainTitle: 'Inactive User Leads',
      headTitle1: 'Inactive User Leads',
      moduleName: 'InactiveUserLeads',
      accessType: 'read',
      type: 'link',
      active: false,
      path: ''
    },
    {
      // id: 17,
      id: 28,
      icon: 'user',
      mainTitle: 'Bulk Uploads',
      headTitle1: 'Bulk Uploads',
      moduleName: 'BulkUploads',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        {
          title: 'Stock In Hand',
          icon: 'table',
          type: 'link',
          active: true,
          path: '',
        },
        {
          title: 'Outstanding Amount',
          icon: 'table',
          type: 'link',
          active: true,
          path: '',
        }
      ]
    },
    {
      // id: 20,
      id: 29,
      icon: 'charts',
      mainTitle: 'Manage Currency',
      headTitle1: 'Manage Currency',
      moduleName: 'ManageCurrency',
      accessType: 'read',
      type: 'link',
      active: false,
      item: [
        {
          title: 'Currency',
          icon: 'table',
          type: 'link',
          active: true,
          path: 'manage-currency/currency/list',
        }
      ]
    },
    {
      id: 30,
      icon: 'charts',
      mainTitle: 'Manage Distributor',
      headTitle1: 'Manage Distributor',
      moduleName: 'Distributor',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'distributor'
    },
    {
      id: 31,
      icon: 'charts',
      mainTitle: 'Inactive Opportunity',
      headTitle1: 'Inactive Opportunity',
      moduleName: 'InactiveUserOpportunity',
      accessType: 'read',
      type: 'link',
      active: false,
      path: 'inActiveOpportunity'
    },
    // {
    //   id: 30,
    //   icon: 'maps',
    //   mainTitle: 'Live Location',
    //   headTitle1: 'Live Location',
    //   moduleName: 'LiveLocation',
    //   accessType: 'read',
    //   type: 'link',
    //   active: false,
    //   path: 'live-locations'
    // },
    // {
    //   id: 31,
    //   icon: 'charts',
    //   mainTitle: 'SONumberEntry',
    //   headTitle1: 'SONumberEntry',
    //   moduleName: 'SONumberEntry',
    //   accessType: 'read',
    //   type: 'link',
    //   active: false,
    //   // path:'opportunity/list-Opportunity'
    //   path:'so-number-entry',
    //   item: [
    //     {
    //       title: 'Open',
    //       icon: 'table',
    //       type: 'link',
    //       active: true,
    //       path: 'so-number-entry/OPEN',
    //     },
    //     {
    //       title: 'Closed',
    //       icon: 'table',
    //       type: 'link',
    //       active: true,
    //       path: 'so-number-entry/CLOSED',
    //     }
    //   ]
    // },




    // {
    //   id: 1,
    //   icon: 'Perk-Ui',
    //   mainTitle: 'Leads',
    //   headTitle1: 'Lead',
    //   moduleName: 'ManageEmail',
    //   accessType: 'read',
    //   item: [
    //     {
    //       title: 'New',
    //       icon: 'table',
    //       type: 'link',
    //       active: true,
    //       path: '/leads/new',
    //     },
    //     {
    //       title: 'Open',
    //       icon: 'Form',
    //       type: 'link',
    //       active: false,
    //       path: '/leads/open'
    //     },
    //     {
    //       title: 'Closed',
    //       icon: 'knowledgebase',
    //       type: 'link',
    //       active: false,
    //       path: '/leads/closed'
    //     }
    //   ]},
    // {
    //   id: 1,
    //   icon: 'Grid',
    //   mainTitle: 'page',
    //   headTitle1: 'pages',
    //   item: [
    //     {
    //       title: 'pages',
    //       icon: 'sample-page',
    //       type: 'sub',
    //       active: false,
    //       children: [
    //         { path: '/pages/sample-page1', title: 'Sample-page1', type: 'link' },
    //         { path: '/pages/sample-page2', title: 'Sample-page2', type: 'link' },
    //       ],
    //     },
    //     {
    //       title: 'Sample-page',
    //       icon: 'sample-page',
    //       type: 'link',
    //       active: false,
    //       path: '/sample-page',
    //       bookmark:true
    //     },
    //   ]
    // },


  ]
  items = new BehaviorSubject<Menu[]>(this.Nvabarmenu);

};